import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Modal } from 'react-bootstrap'
import { Formik, Form } from 'formik'

import BDRText from '../../assets/images/ID_Issuer_Logo_Small.png'
import '../../assets/styles/Main/Footer.css'
import '../../assets/styles/DeactivationPopUp.css'
import versionText from '../../release-version.txt'
import licensesText from '../../Licenses.txt'

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isImpressumModalOpen: false,
      releaseVersion: null
    }

    this.showImpressum = this.showImpressum.bind(this)
    this.readVersionFile = this.readVersionFile.bind(this)
    // this.handleModalSubmit = this.handleModalSubmit.bind(this)
  }

  componentDidMount() {
    this.readVersionFile();
  }

  showDataPrivacyStatement = () => {
    window.location = '/DataPrivacyStatement'
  }

  showLicenses = () => {
    window.location = '/licenses'
  }

  showSupport = () => {
    this.setState({ isSupportModalOpen: true })
  }

  hideSupport = () => {
    this.setState({ isSupportModalOpen: false })
  }


  showImpressum = () => {
    this.setState({ isImpressumModalOpen: true })
  }

  hideImpressum = () => {
    this.setState({ isImpressumModalOpen: false })
  }

  impressumModal = () => {
    return <Modal
      show={this.state.isImpressumModalOpen}
      onHide={this.hideImpressum}
      dialogClassName="modal-90w"
      aria-labelledby="title"
      id="impressum-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="title">
          <h5>
            <FormattedMessage id="impressum" />
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body id="impressumPopUp">
        <Formik initialValues={this.state.confirmDeactivation} onSubmit={this.handleModalSubmit}>
          {({ errors, touched, handleChange, values, handleSubmit }) => (
            <Form id="registerForm">
              <div className="oneComponent">
                <p className="regular"><FormattedMessage id="impressum_company" /></p>
                <p className="regular"><FormattedMessage id="impressum_street" /></p>
                <p className="regular"><FormattedMessage id="impressum_city" /></p>
              </div>
              <div className="oneComponent">
                <p className="regular"><FormattedMessage id="impressum_one" /></p>
                <p className="regular"><FormattedMessage id="impressum_two" /></p>
                <p className="regular"><FormattedMessage id="impressum_three" /></p>
                <p className="regular"><FormattedMessage id="impressum_four" /></p>
                <p className="regular"><FormattedMessage id="impressum_five" /></p>
                <p className="regular"><FormattedMessage id="impressum_six" /></p>
                <p className="regular"><FormattedMessage id="impressum_seven" /></p>
              </div>
              <div className="oneComponent">
                <p className="regular"><FormattedMessage id="impressum_contact" /><FormattedMessage id="impressum_tel" /></p>
                <p className="regular"><FormattedMessage id="impressum_fax" /></p>
                <p className="regular"><FormattedMessage id="impressum_email" /></p>
              </div>
              <div className="oneComponent">
                <p className="regular"><i><b><FormattedMessage id="impressum_disclaimer" /></b></i></p>
                <p className="regular"><i><FormattedMessage id="impressum_content_one" /></i></p>
              </div>
              <div className="oneComponent">
                <p className="regular"><i><FormattedMessage id="impressum_content_two" /></i></p>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  }

  supportModal = () => {
    return <Modal
      show={this.state.isSupportModalOpen}
      onHide={this.hideSupport}
      dialogClassName="modal-90w"
      aria-labelledby="title"
      id="impressum-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="title">
          <h5>
            <FormattedMessage id="support" />
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body id="impressumPopUp">
        <div className="oneComponent">
          <p className="regular"><FormattedMessage id="supportMessage" /></p>
        </div>
      </Modal.Body>
    </Modal>
  }

  readVersionFile = () => {
    var that = this;
    fetch(versionText)
      .then(response => response.text())
      .then(function (data) {
        that.setState({ releaseVersion: data });
      })
      .catch(function (error) {
        //console.log(error)
      })
  }

  render() {
    if (this.props.token === 'emptyToken') {
      return <div></div>
    } else {
      return (
        <>
          <div className="footer header-color">
            <div className="footerContent">
              <div id="versionInfo">
                <p>
                  v{this.state.releaseVersion}
                </p>
              </div>
              <div id="informationFooter" className="displayFlexCentered">
                <button className="button_tertiary medium_button" id="impressum"
                  onClick={e => this.showImpressum(e)}>
                  <FormattedMessage id="impressum"></FormattedMessage>
                </button>
                <button className="button_tertiary medium_button" id="support"
                  onClick={e => this.showSupport(e)}>
                  <FormattedMessage id="support"></FormattedMessage>
                </button>
                <button className="button_tertiary medium_button" id="DPSLink"
                  onClick={e => this.showDataPrivacyStatement(e)}>
                  <FormattedMessage id="dataPrivacyStatement"></FormattedMessage>
                </button>
                <button className="button_tertiary medium_button" id="licenses"
                  onClick={e => this.showLicenses(e)}>
                  <FormattedMessage id="licenses"></FormattedMessage>
                </button>
              </div>
              <div id="footerLogo">
                <img src={BDRText} alt="" />
              </div>
            </div>
          </div>
          {this.impressumModal()}
          {this.supportModal()}
        </>
      )
    }
  }
}

export default Footer