const messages_de = {

  //Onboarding GUI
  profileSelectionPanelWelcome: "Willkommen beim ID Issuer Onboarding-Prozess!",
  profileSelectionPanelSelect: "Bitte w\u00E4hlen Sie Ihr Profil.",
  profileSelectionPanelProducerImporter: "Hersteller/Importeur",
  profileSelectionPanelOther: "Handel, Logistik, Labore",
  profileSelectionPanelAccredited: "Zugelassener externer Betreiber",
  companyData: "Firmen Daten",
  taxAndLegalInformation: "Steuerinformation und Rechtsform",
  languageSettings: "Spracheinstellungen",
  invoicingLanguage: "Bitte wählen Sie die Sprache für Ihre Rechnung",
  invoicingLanguageDescription: "Wählen Sie die Sprache für Ihre Rechnungsstellung",
  billingAddressSameAsInvoice: "Verwenden Sie die Firmenadresse als Rechnungsadresse.",
  forwardCaption: "Weiter",
  finishCaption: "Beenden",
  backCaption: "Zurück",
  accreditedEoIdCaption: "Akkreditierte EO-ID",
  secRepoWarning: "Nach Dr\u00FCcken des Vorwärts Knopfes warten sie bitte auf die Pr\u00FCfung der EO-ID durch das Secondary Repository.",
  eoIdFailed: "Die eingegebene EO-ID wurde durch das Secondary Repository nicht best\u00E4tigt. Im Zweifel pr\u00FCfen Sie bitte ihre ID zusammen mit ihrer zust\u00E4ndigen Ausgabestelle.",
  unknownAccreditedEoid:"Die von ihnen angegebene EO-ID stammt nicht von einem gültigen ID Issuer.",
  secRepoUnavailable: "Das Secondary Repository ist zur Zeit nicht erreichbar. Bitte probieren Sie es sp\u00E4ter nochmal.",
  termsAndConditions: "Ich akzeptiere die Allgemeinen Geschäftsbedingungen",
  termsAndConditionsError: "Fehler beim Herunterladen der Allgemeinen Geschäftsbedingungen",
  dataPrivacyStatement: "Datenschutzerklärung",
  licenses: "Lizenzhinweise Dritter",
  registrationSuccessfulPanelLabel: "Ihre Registrierungsanfrage wurde empfangen.<br>Sie werden in K\u00FCrze eine Best\u00E4tigungsemail erhalten.<br><br>Wenn Sie innerhalb der n\u00E4chsten 24 Stunden keine E-Mail von ID Issuer erhalten, <br>wenden Sie sich bitte an unseren Service Desk unter ",
  notificationWarning: "Eine Registrierung ist derzeit nicht m\u00F6glich. Bitte versuche es sp\u00E4ter erneut.",
  notificationConflict: "Ihre Angaben enthalten Daten, die im System bereits vorhanden sind.",
  verificationMessage: "Geben Sie den Code ein, den Sie per SMS erhalten haben.<br><br> Wenn Sie keine SMS von ID Issuer erhalten haben,<br> wenden Sie sich bitte an unser Service Desk unter ",
  verificationSuccessful: "Ihre Daten wurden erfolgreich verifiziert. Bald erhalten Sie eine E-Mail mit den weiteren Schritten. <br>Wenn Sie innerhalb der nächsten 24 Stunden keine E-Mail von ID Issuer erhalten, <br>wenden Sie sich bitte an unser Service Desk unter ",
  verificationConflict: "Ihre Daten wurden bereits verifiziert. Wenn Sie keine E-Mail von ID Issuer erhalten haben, wenden Sie sich bitte an unser Service Desk unter Id-issuer@bdr.de",
  verificationError: "Eingegebene Daten sind ungültig. Bitte stellen Sie sicher, dass Sie die richtigen Daten eingegeben haben, und versuchen Sie es erneut. Wenn Sie Probleme haben, wenden Sie sich an unser Service Desk unter Id-issuer@bdr.de",
  proofOfWork: "Ich bin kein Roboter",
  proofOfWorkError: "Bei der Überprüfung ist ein Fehler aufgetreten",
  taxIdentificationWarning: 'Die Steueridentifikation fehlt. Bitte entweder USt-ID, Steuer-ID oder Steuernummer angeben',
  keepDataAndContinue: 'Daten beibehalten und fortfahren',
  correctData: 'Daten korrigieren',
  addressValidationFailedMessage: 'Die eingegebene Adresse konnte nicht erfolgreich überprüft werden und wird daher nicht akzeptiert. Um die Registrierung abzuschließen geben Sie bitte eine gültige Adresse an. Sie können sich dabei durch das "Adresse suchen" Feld unterstützen lassen.',
  automaticVatValidationFailedMessage: "Ihre USt-ID konnte nicht erfolgreich überprüft werden. Sie haben jetzt die Möglichkeit die für die Prüfung relevanten Daten Firmen-Name inkl. Rechtsform, Adresse und USt-ID nochmal zu prüfen. Wählen Sie dafür 'Daten korrigieren'. Wenn Sie sich sicher sind, dass die eingegebenen Daten der USt-Registierung entsprechen können Sie mit 'Daten beibehalten und fortfahren' weitermachen. In diesem Fall wird die Registrierung durchgeführt, allerdings können Sie zunächst keine upUI bestellen. Wenden Sie sich zur Klärung der USt-Informationen bitte an unser Service Team unter id-issuer@bdr.de",
  primaryReminderModalMessage: 'Bitte beachten Sie, dass für eine erfolgreiche UI Bestellung eine zusätzliche Registrierung bei einem Primary Repository Ihrer Wahl notwendig ist.',
  duplicateEODetected: 'Dieser Wirtschaftsteilnehmer ist bereits registriert. Wenn Sie nur das Password für ihren Zugang vergessen haben, können Sie dieses im Login-Dialog mit der Funktion "Forgot password" wiederherstellen. In allen anderen Fällen wenden Sie sich bitte an unser Service Team unter der mail Adresse id-issuer@bdr.de',
  closeButton: "SCHLIESSEN",

  //operations GUI specific warnings
  selectSearchType: "Bitte wählen Sie den Suchtyp.",
  enterSearchText: "Das Eingabefeld für die Suche ist leer.",
  eoDoesNotExists: "Wirtschaftlicher Operator für diese Eingabe existiert nicht.",
  idOrderDoesNotExists: "Die Reihenfolge für diese IDIssuer Bestell ID existiert nicht.",
  buendeOrderDoesNotExists: "Bünde Bestellschein-Nr existiert nicht.",
  customOrderDoesNotExists: "Kundenauftrags ID does existiert nicht.",
  foundItems: "Gefunden",
  buendeOrder: "Bünde Bestellschein",
  customerOrder: "Kundenauftrags",
  pleaseSelect: "Bitte wählen Sie einen Wirtschaftsbeteiligten aus ",
  forEntered: " für eingegeben ",
  searchBy: 'Suche',
  producerImporterDescription:' Produzenten bzw. Importeure mit ',
  producerImporterDescriptionLocation: 'Firmensitz in Deutschland',
  OtherDescription:'Alle anderen Wirtschaftsteilnehmer am Tabakhandel (z.B. Handel oder Labore) mit ',
  OtherDescriptionLocation:'Firmensitz in Deutschland',
  AccreditedDescription:'Produzenten bzw. Importeure mit europäischem ',
  AccreditedDescriptionLocation:'Firmensitz außerhalb Deutschlands',

  //specific operations GUI:
  noOfEconomicOperator: "EO",

  //Impressum
  impressum: "Impressum",
  impressum_company: "Bundesdruckerei GmbH\n",
  impressum_street: "Kommandantenstraße 18\n",
  impressum_city: "10969 Berlin\n",
  // "\n" +
  // "\n" +
  impressum_one: "Vertreten durch die Geschäftsführung:\n",
  impressum_two: "Dr.-Ing. Stefan Hofschen, Vorsitzender der Geschäftsführung (CEO)\n",
  impressum_three: "Christian Helfrich, Geschäftsführer (CFO)\n",
  impressum_four: "Vorsitzender des Aufsichtsrats: Dr. Kai Beckmann\n",
  impressum_five: "Registergericht: Amtsgericht Berlin Charlottenburg\n",
  impressum_six: "Handelsregister-Nr.: HRB 80443\n",
  impressum_seven: "Umsatzsteueridentifikationsnummer: DE 813210005\n",
  // "\n" +
  impressum_contact: "Kontakt:\n",
  impressum_tel: "+49 (0)30 2598-0\n",
  impressum_fax: "Fax: +49 (0)30 2598-2205\n",
  // "\n" +
  impressum_email: "E-Mail: info@bundesdruckerei.de\n",
  // "\n" +

  impressum_disclaimer: "Haftungsausschluss\n",
  impressum_content_one: "Die Bundesdruckerei aktualisiert und ergänzt die Informationen auf ihrer Website nach bestem " +
    "Wissen und Gewissen. Für die Aktualität, Richtigkeit und Vollständigkeit der Informationen " +
    "übernimmt die Bundesdruckerei keine Gewähr. Aufgrund der rechtlichen und technischen Gegebenheiten " +
    "im Internet kann die Bundesdruckerei keine Gewährleistung und Haftung für die Richtigkeit und " +
    "Vollständigkeit des Inhalts der Werke und der eingestellten Informationen, deren maschinenlesbare " +
    "Erfassung, deren elektronische Speicherung in Datenbanken, deren Verbreitung sowie die Einhaltung " +
    "datenschutzrechtlicher und sonstiger Rechtspflichten durch Dritte übernehmen.",
  // "\n\n" +
  impressum_content_two: "Bitte beachten Sie auch unsere Benutzerhinweise zur Verwendung der von uns zur Verfügung " +
    "gestellten Dokumente und Informationen. Sollten Sie darüber hinaus Fragen zu unserem Webangebot " +
    "haben, wenden Sie sich bitte an unsere Internetredaktion.",

  support: "Hilfe",
  supportMessage: "Unser Call und Support Center erreichen Sie per E-Mail über id-issuer@bdr.de. Darüber hinaus stehen wir Ihnen telefonisch von Montag bis Sonntag rund um die Uhr unter +49(0)30 2598 -4044 zur Verfügung. ",


  //Date
  dateYear: 'Jahr',
  dateMonth: 'Monat',

  empty: ' ',
  valueNotAvailable: 'N/A',
  mandatoryFields: 'Dieses Feld ist erforderlich. Bitte ausfüllen.',
  mandatoryFieldsPostalCode: "Das Feld muss ausgefüllt werden. Wenn keine Postleitzahl vergeben wurde, tragen Sie bitte den Wert 'n/a' ein.",
  emptyFields: 'Leerzeichen nicht erlaubt.',
  repeatingLegalForm: "Das Feld 'Unternehmensname ohne die Rechtsform' darf nicht die Rechtsform enthalten.",
  somethingWentWrong: 'Etwas schiefgelaufen. Bitte versuchen Sie es sp\u00E4ter erneut.',
  mapZoom: 'Bitte zoomen und erneut klicken',

  goToFacilities: 'Gehe zu Einrichtungen',
  goToMachines: 'Geh zu Maschinen',
  maximumThreeOptions: 'Sie können maximal drei Filteroptionen auswählen.',
  pleaseFillInput: 'Die Sucheingabe ist leer.',
  duplicateFilter: 'Dieser Filter wurde bereits hinzugefügt.',
  registeredFacilities: 'Registrierte Einrichtungen',
  registeredMachines: 'Registrierte Maschinen',
  addressDoesNotExists: 'Adresse existiert nicht!',
  invalidAddress: 'Bitte geben Sie eine gültige Adresse ein.',
  selectAddress: 'Bitte wählen Sie eine Option.',
  searchAddress: 'Adresse suchen',
  search: 'Suche',
  insertIDfield: 'Eingabewert ist leer.',
  firstConfirm: 'Bitte erst bestätigen!',
  failDeactivate: 'Konto Deaktivierung fehlgeschlagen.',
  welcome: 'Willkommen',
  deleteTIN: 'Löschen Sie die Steueridentifikationsnummern, wenn Sie die USt-Nr. verwenden möchten',
  deleteVAT: 'Löschen Sie die USt-Nr., wenn Sie die Steueridentifikationsnummern verwenden möchten',
  emailSMScommunication: 'Sprache für E-Mail und SMS',
  emailSMScommunicationDescription: 'Wählen Sie die Sprache, mit der der ID Issuer mit Ihnen kommunizieren soll.',
  deLanguage: 'Deutsch',
  engLanguage: 'Englisch',
  exportCsv: 'CSV exportieren',
  GEN_UNIT_UIDS: 'Unit Pack UI',
  GEN_AGGRU_IDS: 'Aggregagtions-UI',
  //++++++++++ BUTTONS:
  editButton: 'Bearbeiten',
  saveButton: 'Speichern',
  cancelCaption: 'Abbrechen',
  submitButtonCaption: 'Best\u00E4tigen',
  //++++++++++ DASHBOARD:
  dashboardGridTitle: 'Liste der Auftragsaktivitäten',

  //++++++++++ MAIN HEADER:
  menuBarFacilities: 'Einrichtungen',
  menuBarMachines: 'Maschinen',
  menuBarManagedRetailers: 'Verwaltete Einzelh\u00E4ndler',
  menuBarOrders: 'Bestellungen',
  menuBarAccountSettings: 'Einstellungen',
  defautlProducer: 'Hersteller',
  logOutButtonCaption: 'Abmelden',

  //++++++++++ GridComponents
  noDataCaption: 'Keine Daten zu zeigen',
  nameCaption: 'NAME',
  idCaption: 'ID',
  customerIdCaption: 'KUNDENNUMMER',
  typeCaption: 'TYP',
  fidCaption: 'EINRICHTUNG',
  midCaption: 'MASCHINE',
  dateCaption: 'ERSTELLT',
  date2Caption: ' DATUM',
  quantityCaption: 'MENGE',
  upUICaption: 'Unit Pack UI',
  aUICaption: 'Aggregagtions-UI',
  upUIShortCaption: 'UpUi',
  aUIShortCaption: 'AUI',
  refreshButtonCaption: 'Zur\u00FCcksetzen',
  statusCaption: 'STATUS',
  detailsCaption: 'EINZELHEITEN',
  downloadCaption: 'HERUNTERLADEN',
  deliveryCaption: 'LIEFERUNG',
  //orderIdCaption: "AUFTRAG ID",
  confirmationCaption: 'BEST\u00E4TIGUNGCODE',
  confirmationCaptionGrid: 'BEST\u00E4TIGUNG',
  addressCaption: 'ADRESSE',
  capacityCaption: 'HERSTELLE',
  producerCaption: 'HERSTELLER',
  modelCaption: 'MODEL',
  customerOrderIdHeaderCaption: 'IHRE BESTELLNUMMER',
  endOfCancellationPeriodCaption: 'STORNIERUNG BIS',

  //++++++++++ Address Form:
  addressFormCaption: 'Registrierte Adresse',
  invoiceFormCaption: 'Rechnungsadresse',
  searchFieldCaption: 'Nach Adresse suchen...',
  streetCaption: 'Stra\u00DFe',
  streetDescription: 'Stra\u00DFenname',
  houseNumberCaption: 'Hausnummer',
  houseNumberDescription: 'Hausnummer',
  addInfoCaption: 'Zusätzliche Angaben zur Adresse',
  addInfoDescription: 'Hier können Sie zusätzliche Informationen eingeben, die die Adressangabe näher beschreiben, z.B. "befindet sich im 2. Stock"',
  zipCodeCaption: 'PLZ',
  zipCodeDescription:
    "Die Postleitzahl, Post Code oder PIN ist eine länderspezifische Folge von Buchstaben oder Ziffern oder eine Mischungen von beidem, manchmal erweitert um Satzzeichen oder Leerzeichen. Wenn keine Postleitzahl vergeben wurde, tragen Sie bitte den Wert 'n/a' ein.",
  cityCaption: 'Stadt',
  cityDescription: 'Stadtname',
  countryRegCaption: 'ISO Alpha-2 L\u00E4nderkennzeichen',
  countryRegDescription:
    'Zwei-Zeichen Länder Code gemäß ISO 3166-1 Standard. Dieser Code wird genutzt, um Länder, Regionen und Orte von speziellem geographischen Interesses zu bezeichnen.',
  countryCaption: 'Land des Unternehmenssitzes',
  countryDescription: 'Das Land, in dem das Unternehmen ansässig ist',

  //++++++++++ LOGIN:
  usernameTextFieldCaption: 'Benutzer-Kennung',
  passwordTextFieldCaption: 'PassworT',
  logInButtonCaption: 'AnmeldeN',
  infoLabel: 'Falsche Benutzer-Kennung oder Passwort!',
  passwordSuccessfullyChanged: ' Passwort wurde erfolgreich ge\u00E4ndert.',
  passwordCouldNotChanged:
    'Es ist nicht m\u00F6glich, Ihr Passwort zu \u00E4ndern. Bitte versuchen Sie es sp\u00E4ter erneut.',
  forgotPasswordLabel: 'Dies ist ein Vorgang, wenn Sie Ihr Passwort vergessen haben.',
  browserError: 'Dieser Browser wird nicht unterst\u00FCtzt. Bitte verwenden Sie einige der unterst\u00FCtzten:',
  buttonClearCaption: 'Alles l\u00F6schen',
  loginLabelTest:
    'Dies ist eine Testumgebung. Die hier beantragte Daten k\u00F6nnen nicht f\u00FCr den produktiven Betrieb eingesetzt werden.',
  labelTest:
    'Dies ist eine Testumgebung. Die hier beantragte Daten k\u00F6nnen nicht f\u00FCr den produktiven Betrieb eingesetzt werden.',

  //++++++++++ ACCOUNT SETTINGS:
  warningCaption: 'WARNUNG',
  warningMessage:
    'Möchten Sie wirklich Ihr Konto DEAKTIVIEREN?  Alle Einrichtungen und Maschinen werden entfernt und ausstehende Bestellungen storniert.',
  eoDeactivateCheckbox: 'Ich bin mir sicher.',
  resetCaption: 'Passwort zur\u00FCcksetzen',
  forgotPassworCaption: 'Haben Sie Ihr Passwort vergessen?',
  nexCaption: 'Weiter',
  resetProcedurCaption: 'VORGEHENSWEISE ZUM ZUR\u00DCCKSETZEN DES PASSWORTS',
  resetInfoMessage:
    'Wenn Sie auf die Schaltfl\u00E4che Passwort zur\u00FCcksetzen klicken und die \u00DCberpr\u00FCfung erfolgreich verlaufen ist, senden wir Ihnen ein neues Einmal-Passwort per SMS an die Mobilfunknummer, die Sie w\u00E4hrend des Registrierungsvorgangs eingegeben haben.',
  wrongData: 'Daten sind nicht korrekt. Bitte überprüfe es nocheinmal.',
  //+++++ General settings:
  accountSettingsGeneralSettings: 'Allgemeine Einstellungen',
  eoIdentifierCaption: 'ID des Wirtschaftsteilnehmers',
  eoIdentifierDescription: 'Wirtschaftsteilnehmer-Identifikationscode',
  confirmationCodeCaption: 'Best\u00E4tigungscode',
  confirmationCodeDescription: 'Best\u00E4tigungscode des Wirtschaftsteilnehmers',
  eoNameCaption: 'Unternehmensname ohne die Rechtsform',
  eoNameDescription: 'Name des Wirtschaftsteilnehmers',
  eoNameWithLegalForm: 'Wirtschaftsteilnehmer',
  eoAlternativeNameCaption: 'Alternativer Name',
  eoAlternativeNameDescription: 'Alternativer oder abgek\u00FCrzter Name des Wirtschaftsteilnehmers',
  mobilePhoneCaption: 'Mobiltelefon',
  mobilePhoneDescription:
    'Mobiltelefonnummer mit internationaler Vorwahl. Wird zur Übermittlung von IDIssuer Passwörtern verwendet.',
  eoEmailCaption: 'E-Mail',
  eoEmailDescription:
    'E-Mail Adresse, die im Folgenden zur Kommunikation mit dem Wirtschaftsteilnehmer verwendet werden soll.',
  vatNumberCaption: 'USt-ID',
  vatNumberDescription: 'Umsatzsteuer-Identifikationsnummer des Wirtschaftsteilnehmers',
  taxRegistrationNumberCaption: 'Steuer-ID',
  taxRegistrationNumberDescription: 'Steuer-Identifikationsnummer des Wirtschaftsteilnehmers',
  steuernummerCaption: 'Steuernummer',
  steuernummerDescription: 'Steuernummer des Wirtschaftsteilnehmers',
  exciseNumberCaption: 'VSt-Nr',
  exciseNumberDescription: 'Verbrauchsteuer-Nummer der Wirtschaftsteilnehmer',
  otherEoIdNCaption: 'Andere Wirtschaftsteilnehmer-Identifikationscodes (EOIDs)',
  otherEoIdNDescription:
    'Identifikationscodes des Wirtschaftsteilnehmers (EOIDs), die von anderen ID-Issuern vergeben wurden.',
  legalFormCaption: 'Rechtsform',
  legalFormDescription: 'Unternehmens-Rechtsform',
  legalFormPlaceHolder: 'Bitte wählen Sie eine Rechtsform',
  legalFormNoCountry: 'Bitte erst das Land auswählen',

  correctionWarning1: 'Alte Firmenanschrift wie Rechnungsadresse.',
  correctionWarning2: 'Möchten Sie, dass auch Änderungen an den Rechnungsdaten vorgenommen werden?',
  correctionYES: 'JA',
  correctionNO: 'NEIN',

  completeCompanyName: 'Voller Unternehmensname',
  companyAbbreviation: 'Firmenkürzel', //SHOULD NEVER BE SEEN
  //+++++ Invoicing:
  accountSettingsInvoice: 'Fakturierung',
  invoiceCompanyCaption: 'Name der Rechnungsfirma',
  invoiceCompanyDescription: 'Name der Rechnungsfirma.',
  invoiceEmailCaption: 'Rechnungs E-Mail',
  invoiceEmailDescription: 'E-Mail-Adresse, die für die Rechnungsstellung verwendet wird.',
  invoicChangeMethod: 'Methode \u00E4ndern',
  invoicChangeInvoiceMethod: 'Rechnungsmethode \u00E4ndern',
  //+++++ Api key:
  accountSettingsApiKey: 'API Schl\u00FCssel',
  accountSettingsApiKeyInfo:
    'Bitte speichern Sie diese Daten. Wenn Sie diese Ansicht schlie\u00DFen, gehen alle Informationen verloren.',
  accountSettingsApiKeyUsername: 'Benutzer-Kennung',
  accountSettingsApiKeyUsernameDescription: 'Der user name, der bei der Verwendung der API angegeben werden muss.',
  accountSettingsApiKeyPassword: 'Passwort',
  accountSettingsApiKeyPasswordDescription: 'Das API password, das bei der Verwendung der API angegeben werden muss.',
  accountSettingsApiKeySecret: 'Geheimnis',
  accountSettingsApiKeySecretDescription: 'Das API secret, das bei der Verwendung der API angegeben werden muss.',
  accountSettingsApiKeyNewKey: 'Neuer Schl\u00FCssel',
  //+++++ Change password:
  accountSettingsPasswordReset: 'Passwort \u00C4ndern',
  changePasswordTitle: 'PASSWORT \u00C4NDERN',
  changePasswordOldPassword: 'Altes Passwort',
  changePasswordNewPassword: 'Neues Passwort',
  changePasswordConfirmPassword: 'Passwort best\u00E4tigen',
  changePasswordWarning1: 'Passw\u00F6rter sind nicht identisch',
  changePasswordWarning2: 'Das neue Passwort darf nicht mit dem Benutzernamen identisch sein',
  changePasswordWarning3: 'Bitte neues Passwort eingeben',
  changePasswordWarning4: 'Bitte neues Passwort nochmal eingeben',
  changePasswordWarning5: 'Das Passwort muss mindestens 8 Zeichen lang sein',
  changePasswordWarning6: 'Das Passwort muss Ziffern enthalten',
  changePasswordWarning7: 'Das Passwort muss Kleinbuchstaben enthalten',
  changePasswordWarning8: 'Das Passwort muss Grossbuchstaben enthalten',
  changePasswordWarning9: 'Das Passwort darf keine Leerzeichen enthalten',
  changePasswordWarning10: 'Bitte geben Sie ein altes Passwort ein',
  notificationWrongOldPassword: 'Falsches altes Passwort. Bitte versuche es erneut.',
  notificationPassCouldNotChange:
    'Es ist nicht m\u00F6glich, Ihr Passwort zu \u00E4ndern. Bitte versuchen Sie es sp\u00E4ter erneut.',
  notificationPassChanged: 'Ihr Passwort wurde ge\u00E4ndert',
  //+++++ Deactivate operator:
  accountSettingsDeregisterAccount: 'Benutzerkonto deaktivieren',
  deregisterEOFormCaption: 'Deaktivierung eines Wirtschaftsteilnehmers',
  deregisterEOButtonCaption: 'Wirtschaftsteilnehmer deaktivieren',

  //++++++++++ FACILITY OPERATIONS:
  registerFacilityButtonCaption: 'Einrichtung registrieren',
  registerFacilityFormCaption: 'Registrierung einer Einrichtung / eines Standortes',
  facilityCorrectionCaption: 'Daten zu einer Einrichtung korrigieren',
  editFacilityButtonCaption: 'Daten zu einer Einrichtung  bearbeiten',
  deregisterFacilityFormCaption: 'Deaktivieren einer Einrichtung',
  deregisterFacilityButtonCaption: 'Einrichtung deaktivieren',
  facilityData: 'Einrichtungsdaten',
  filterBy: 'Filtern nach',
  myFacilities: 'Meine Einrichtungen',
  noOfFacilities: 'Einrichtungen',
  //+++++ Facility Registration:
  facilityStatus1: 'Kein Steuerlager',
  facilityStatus2: 'Hat Status Steuerlager',
  facilityTypeCaption: 'Einrichtungstyp',
  facilityTypeDescription: 'Typ der Einrichtung',
  facilityTypeOtherCaption: 'Typ Sonstige',
  facilityTypeOtherDescription: 'Beschreibung zum Typ Sonstige',
  facilityOtherFidNCaption: 'Andere Einrichtung-Identifikationscodes (FID)',
  facilityOtherFidNDescription:
    'Identifikationscodes von Einrichtungen (FIDs), die von anderen ID-Issuern vergeben wurden.',
  facilityOtherFidNPlaceholder:
    "Andere FIDs kommasepariert eingeben (e.g. '51q<$000000000025000020, 51q<$000000000025000021').",
  //+++++ Facility Correction:
  facilityIdentifierCaption: 'Einrichtung-Identifikationscode',
  facilityIdentifierDescription: 'Identifikationscode der Einrichtung',
  //+++++ Facility Delete:

  facilityIsRegisteredOnBehalfOfCaption: 'Registriert durch Dritte',
  facilityIsRegisteredOnBehalfOfDescription:
    'Kennzeichnet den Vorgang einer Registrierung eines Betreibers einer erstern Verkaufsstellen durch einen Dritten.',
  facilityIsRegisteredOnBehalfOf_0:
    'Registrierung erfolgt NICHT stellvertretend f\u00FCr einen Betreiber einer erstern Verkaufsstelle',
  facilityIsRegisteredOnBehalfOf_1:
    'Registrierung erfolgt stellvertretend f\u00FCr einen Betreiber einer erstern Verkaufsstelle',
  facilityEoIdentifierDescription: 'Identifikationscode des Wirtschaftsteilnehmers, der diese Einrichtung betreibt',
  facilityType_1: 'Produktionsstelle mit Lager',
  facilityType_2: 'Lager',
  facilityType_3: 'Verkaufsstelle',
  facilityType_4: 'Sonstige',
  ManufacturingSiteWithWarehouse: 'HERSTELLUNG',
  StandaloneWarehouse: 'WARENHAUS',
  RetailOutlet: 'VERKAUF',
  Other: 'ANDERE',
  facilityStatusCaption: 'Einrichtung hat Status Steuerlager',
  facilityStatusDescription: 'Angabe, ob ein Teil der Einrichtung den Status eines Steuerlagers hat',
  facilityExciseNumber2Caption: 'VSt-Nr.',
  facilityExciseNumber2Description: 'Verbrauchsteuer-Nummer der Einrichtung',
  listFacilitiesButtonCaption: 'Einrichtungen auflisten',

  //++++++++++ MACHINE OPERATIONS:
  registerMachineFormCaption: 'Registrierung einer Maschine',
  registerMachineButtonCaption: 'Maschine registrieren',
  machineCorrectionCaption: 'Daten zu einer Maschine korrigieren',
  editMachineButtonCaption: 'Daten zu einer Maschine bearbeiten',
  deregisterMachineButtonCaption: 'Maschine deaktivieren',
  deregisterMachineFormCaption: 'Deaktivierung einer Maschine',
  machineData: 'Maschinendaten',
  myMachines: 'Meine Maschinen',
  noOfMachines: 'Maschinen',
  //+++++ Machine Registration:
  machineProducerCaption: 'Hersteller',
  machineProducerDescription: 'Die Maschine gemäß TPD, die zur Herstellung des Tabak Produkts genutzt wird.',
  machineModelCaption: 'Modell',
  machineModelDescription: ' Maschinenmodell',
  machineSerialNumberCaption: 'Seriennummer',
  machineSerialNumberDescription: 'Seriennummer der Maschine',
  machineCapacityCaption: 'Herstelle',
  machineCapacityDescription:
    'Maximale Kapazit\u00E4t w\u00E4hrend eines 24-st\u00FCndigen Produktionszyklus, ausgedr\u00FCckt in Packungen',
  //+++++ Machine Correction:
  machineIdentifierCaption: 'Maschinen-Identifikationscode',
  machineIdentifierDescription: 'Maschinen-Identifikationscode für diese Einrichtung',
  //+++++ Machine Delete:

  //++++++++++ ORDER OPERATIONS:
  myOrders: 'Meine Bestellungen',
  noOfOrders: 'Aufträge',

  orderStatusStored: 'GESPEICHERT',
  orderStatusInProcessing: 'IN BEARBEITUNG',
  orderStatusReady: 'BEREIT',
  orderStatusDelivered: 'GELIEFERT',
  orderStatusClosed: 'ABGESCHLOSSEN',
  orderStatusCanceled: 'STORNIERT',
  orderStatusPending: 'DERZEIT IN BEARBEITUNG BEIM SECONDARY REPOSITORY',
  orderStatusPendingHtml: 'DERZEIT IN BEARBEITUNG BEIM SECONDARY REPOSITORY',
  orderStatusRejected: 'ABGELEHNT DURCH DAS SECONDARY REPOSITORY',
  orderStatusRejectedHtml: 'ABGELEHNT DURCH DAS SECONDARY REPOSITORY',
  //++++++++++ ORDER A_UI
  orderAUICaption: 'aUIs bestellen',
  reqQuantityCaption: 'Menge',
  reqQuantityAuiDescription: 'Beantragte Menge individueller Erkennungsmerkmale auf Umverpackungsebene',
  webHookBaseCaption: 'Web Hook Base',
  webHookBaseDescription:
    'Sofern angegeben, ruft der ID Issuer den Web Hook f\u00FCr eine Bestellung auf, sobald die Erkennungsmerkmale bezogen werden k\u00F6nnen',
  orderProcessingCaption: 'Vearbeitungsmodus',
  orderProcessingPriorityCaption: 'PRIORITÄT',
  orderProcessing_1: 'STANDARD',
  orderProcessing_2: 'SOFORT',
  binderValidatorOrderQuantity: 'Die Menge muss eine Zahl zwischen 1 und 1 000 000 sein. Nur Zahlen sind erlaubt.',
  binderValidatorHouseNumberInvalidForGermany: 'Für Hausnummern sind nur die folgenden Zeichen erlaubt: Ziffern, Buchstaben, Leerzeichen, Sonderzeichen - /',
  //++++++++++ ORDER UP_UI
  upUIOrderWithoutMachineLabel: 'Hier nur Bestellung von Unit-Paket-UIs ohne Zuordnung einer registrierten Maschine.',
  upUiOrderTitle: 'Beantragung individueller Erkennungsmerkmale auf Packungsebene',
  orderUpUIButtonCaption: 'upUIs bestellen',
  tpBrandCaption: 'Marke',
  tpBrandDescription: 'Tabakproduktmarke',
  productWeightCaption: 'Bruttogewicht der Packung',
  productWeightDescription:
    'Durchschnittliches Bruttogewicht der Packung, einschlie\u00DFlich Verpackung, in Gramm, mit 0.1 Gramm Genauigkeit',
  intendedMarketCaption: 'Absatzmarkt',
  intendedMarketDescription: 'F\u00FCr den Einzelhandelsverkauf vorgesehenes Land',
  intendedRoute2Caption: 'Transportweg',
  intendedRoute2Description:
    'Erstes Land des Transports auf dem Land-/Wasser-/Luftweg, nachdem das Erzeugnis den Herstellungsmitgliedstaat oder den Einfuhrmitgliedstaat verlassen hat, festgestellt mittels einer Kontrollstation an der Landesgrenze, dem n\u00E4chstgelegenen Seehafen bzw. dem n\u00E4chstgelegenen Flughafen',
  countryCode: 'L\u00E4nderkennzeichen',
  tpIdCaption: 'Tabakproduktkennung',
  tpPnCaption: 'Tabakprodukt-Produktnummer',
  gtinCaption: 'GTIN',
  cnCodeCaption: 'Kombinierte Nomenklatur',
  cnCodeDescription: 'Code der kombinierten Nomenklatur des Produkts',
  customerOrderIdCaption: 'Ihre Bestellnummer',
  customerOrderIdDescription: 'Die ID Issuer-Bestellnummer für ihre UI Bestellung.',
  productTypeCaption: 'Tabak Produkttyp',
  productTypeDescription: 'Typ des Tabakprodukts',
  productOtherTypeCaption: 'Sonstige',
  productOtherTypeDescription: 'Sonstige',
  productType_1: 'Zigarett',
  productType_2: 'Zigarr',
  productType_3: 'Zigarill',
  productType_4: 'Tabak zum Selbstdrehe',
  productType_5: 'Pfeifentaba',
  productType_6: 'Wasserpfeifentaba',
  productType_7: 'Tabak zum oralen Gebrauc',
  productType_8: 'Schnupftaba',
  productType_9: 'Kautaba',
  productType_10: 'Neuartiges Tabakerzeugni',
  productType_11: 'Sonstige',
  deliveryMethodCaption: 'Zustellungsmethode',
  deliveryMethodDescription:
    'Wählen Sie Liefermethode, mit der die UI ausgeliefert werden soll. Mögliche Werte sind entweder elektronisch oder physisch.',
  deliveryMethod_1: 'Elektronisch',
  deliveryMethod_2: 'Physisch',
  buendeOrderIdCaption: 'Bünde Bestellschein-Nr.',
  buendeorderIdDescription: 'Bünde Bestellschein-Nr.',
  orderPositionCaption: 'Position',
  orderPositionDescription: 'Positionsnummer entsprechend des B\u00FCnde Bestellscheins',
  orderAmountCaption: 'Menge',
  orderAmountDescription: 'Bestellbetrag',
  importCaption: 'Import',
  importDescription: 'Wählen Sie, ob das Tabak Produkt ausserhalb der EU hergestellt wird.',
  import_1: 'Nein',
  import_2: 'Ja',
  reqQuantityUpuiDescription: 'Beantragte Menge individueller Erkennungsmerkmal',
  binderValidatorIntendedMarket: 'Ung\u00FCltiger Absatzmarkt.',
  binderValidatorIntendedRoute: 'Ung\u00FCltiger Transportweg',
  //+++++ RECALL ORDER:
  orderIdCaption: 'Auftrag ID',
  recallCaption: 'Stornierung',
  recallOrdersCaption: 'Bestellung stornieren',
  recallCodeCaption: 'Stornierungscode',
  recallReasonCaption: 'Stornierungsgrund',
  recallReasonWarning:
    'Stornierungsgrund mindestens ein Zeichen lang sein und darf h\u00F6chsten 50 Zeichen enthalten.',
  recallReason_1: 'Das gemeldete Ereignis fand nicht statt',
  recallReason_2: 'Die Meldung enthielt fehlerhafte Informationen',
  recallReason_3: 'Sonstiges',

  upOrderCaption: 'UI Bestellung',
  upOrderDescription: 'UI Bestellung',
  upOrderValueCaption: 'UI Bestelldaten',
  upOrderValueDescription: 'Daten der UI Bestellung',
  upUIOrderAnnounceButton: 'Bestelldetails abrufen.',
  upUIOrderWarningNoInput: 'Bitte die B\u00FCnde Bestell Nr. eingeben.',
  upUIOrderWarningWrongInput: 'Diese B\u00FCnde Bestell Nr. ist unbekannt:',
  upUIOrderWarningClickItem: 'Bitte eine Order Position ausw\u00E4hlen.',
  upUIOrderWarningAnnounceOrder: "Bitte den Knopf 'Bestelldetails abrufen' dr\u00FCcken.",

  orderProcessingDescription:
    'Der Bearbeitungsmodus legt fest, wie die Bestellung bearbeitet wird. M\u00F6gliche Werte sind: STANDARD MODUS -> Bestellung wird wie durch die TPD definiert behandelt, SOFORT -> Bestellung wird sofort bearbeitet und bereitgestellt, eine Stornierung ist NICHT mehr m\u00F6glich!',
  listOrdersButtonCaption: 'Bestellungen aufliste',

  //++++++++++ RETAILER OPERATIONS:
  myRetailers: 'Meine Einzelhändler',
  noOfRetailers: 'Verwaltete Einzelhändler',
  retailerData: 'Verwaltete Händlerdaten',
  registerEOButtonCaption: 'Wirtschaftsteilnehmer registrieren',
  updateEOButtonCaption: 'Daten zu einem Wirtschaftsteilnehmerd korrigieren',
  registerEO3rdPartyFormCaption: 'Registrierung von Betreibern erster Verkaufsstellen durch Dritte',
  correctionEO3rdPartyCaption:
    ' Daten zur Registrierung von Betreibern erster Verkaufsstellen durch Dritte korrigieren',
  correctionEOCaption: 'Daten zu einem Wirtschaftsteilnehmerd korrigieren',
  retailersFacilities: 'einrichtungen',

  // Validation
  //++++++++++THIS PART IS COPIED FROM Vaadin/Core/PatternStrings file
  binderValidatorEoIdentifier:
    'Wirtschaftsteilnehmer-Identifikationscode muss mindestens ein Zeichen lang sein und darf h\u00F6chsten 50 Zeichen enthalten.',
  binderValidatorConfirmationCode:
    'Der Best\u00E4tigungscode muss mindestens ein Zeichen lang sein und darf h\u00F6chsten 20 Zeichen enthalten.',
  binderValidatorFid:
    'Einrichtung-Identifikationscode (FID) muss mindestens ein Zeichen lang sein und darf h\u00F6chsten 50 Zeichen enthalten.',
  binderValidatorMid:
    'Maschinen-Identifikationscode (MID) muss mindestens ein Zeichen lang sein und darf h\u00F6chsten 50 Zeichen enthalten.',
  otherIdsValidator:
    'Identifikationscode muss mindestens ein Zeichen lang sein und darf h\u00F6chsten 50 Zeichen enthalten.',
  //++++++++++
  binderValidatorName:
    'Wirtschaftsteilnehmer muss mindestens ein Zeichen lang sein und darf h\u00F6chsten 100 Zeichen enthalten.',
  binderValidatorEoEmail: 'E-Mail muss zwischen 5 und 80 Zeichen lang sein.',
  binderValidatorMobilePhone: 'Ung\u00FCltiges Mobiltelefon.',
  binderValidatorEoExciseNumber:
    "Die SEED beginnt mit dem L\u00E4nderkennzeichen aus zwei Buchstaben entprechend ISO-3166-1:2013 alpha-2 (e.g. 'LU'), gefolgt von 11 alphanummerischen Zeichen, die den Wirtschaftsteilnehmer identifizieren. Ggf. werden links Nullen eingef\u00FCgt, um auf 11 Stellen zu kommen.",
  binderValidatorHouseNumber: 'Die Hausnummer sollte mindestens eine Zahl enthalten.',
  binderValidatorCountryReg: 'Ung\u00FCltiger ISO Alpha-2 L\u00E4nderkennzeichen.',
  binderValidatorTpPn: 'EU-CEG verwendete Produktnummer f\u00FCr das Tabakerzeugnis besteht aus 1-30 Zeichen.',
  binderValidatorPCn:
    'Code der Kombinierten Nomenklatur (KN) muss 4 | 7 | 10 Zeichen lang sein. Die ersten 4 Stellen sind Ziffern. z.B. 1234 | 1234 12 | 1234 12 34',
  binderValidatorVat: 'Ung\u00FCltige USt-Nr. Die USt-Nr muss mit dem 2 Zeichen L\u00E4nder Code beginnen.',
  binderValidatorTin: 'Ung\u00FCltiges Steueridentifikationsnummernformat!',
  VatCountryCode: 'Falsches Format. Eine USt-Nr. beginnt mit zwei Zeichen für das Land gefolgt von max. 12 weiteren Zeichen/Ziffern',
  NonGermanVatGermanAddress: 'Für in Deutschland ansässige Unternehmen muss die USt-ID mit "DE" beginnen, gefolgt von neun Ziffern',
  otherIdsSizeValidator: 'Sie k\u00F6nnen maximal 10 IDs hinzuf\u00FCgen.',
  binderValidatorCapacity: 'Nur Zahlen sind zul\u00E4ssig!',
  binderValidatorWeight:
    "Das Gewicht muss eine Zahl mit '.' sein, die als Trennzeichen verwendet wird, wenn Nachkommastellen angegeben werden.",
  binderValidatorBuendeId: 'Die B\u00FCnde Bestell-ID muss zwischen 1 und 35 Zeichen lang sein..',
  binderValidatorOrderPosition: 'Bestellposition muss eine Zahl sein.',
  binderValidatorTpId: "Die Tabakprodukt-ID besteht aus Zahlen und Bindestrichen im Format 'NNNNN-NN-NNNNN'.",
  binderValidatorGtin: 'GTIN besteht aus 14 Zahlen.',

  //Orders
  ordersSearch: 'Such',
  ordersNewUpUiOrder: 'Neue upUI Bestellung',
  ordersNewAUiOrder: 'Neue aUI Bestellung',
  downloadButtonEnabledCaption: 'Laden Sie die generierten IDs herunter',
  downloadButtonDisabledCaption: 'Der Download von Bestellungen wird nach Abschluss der Bearbeitung aktiviert',
  ordersSearchFrom: 'Von',
  ordersSearchTo: 'Bis',
  ordersSearchId: 'Bitte ID eingeben',

  //Search
  facilitySearch: 'Suche nach FID, Typ, Stadt, Stra\u00DFe',
  machineSearch: 'Suche nach MID, FID, Modell, Hersteller, Seriell Nummer',
  managedRetailerSearch: 'Suche nach EOID, Name, Stadt, Stra\u00DFe',

  //++++++++++ ORDERS WARRNING MESSAGES
  ReqQuantityNull: 'Menge muss angegeben werden.',
  ReqQuantityMin: 'Menge darf nicht leer sein.',
  ReqQuantityMax: 'Menge darf nicht l\u00E4nger als 1000000 Zeichen sein.',
  NotificationWebHookBaseSize: 'Web Hook Base darf nicht l\u00E4nger als 250 Zeichen sein.',
  ProcessTypeNull: 'Verarbeitentyp muss angegeben werden.',
  TobaccoProductNull: 'Tabakprodukt muss angegeben werden.',
  ProductWeightNull: 'Bruttogewicht der Packung muss angegeben werden.',
  ProductWeight: 'Das Packungsgewicht muss mindestens 0,1 Gramm betragen.',
  IntendedMarketNull: 'Absatzmarkt Land muss angegeben werden.',
  IsAcrossBorderNull: 'Transportweg muss angegeben werden.',
  IsImportedNull: 'Importwert muss angegeben werden.',
  DeliveryMethodNull: 'Zustellungsmethode muss angegeben werden.',
  BuendeOrderIdSize: 'Die B\u00FCnde Bestell-ID darf nicht l\u00E4nger als 35 Zeichen sein.',
  TobaccoProductBrandNull: 'Tabakproduktmarke muss angegeben werden.',
  TobaccoProductBrandSize: 'Tabakproduktmarke darf nicht l\u00E4nger als 200 Zeichen sein.',
  ProductBrandIdNull: 'Tabakproduktkennung muss angegeben werden.',
  ProductBrandIdInvalid: "Die Tabakprodukt-ID besteht aus Zahlen und Bindestrichen im Format 'NNNNN-NN-NNNNN.",
  ProductBrandNumberNull: 'Tabakprodukt-Produktnummer muss angegeben werden.',
  ProductBrandNumberInvalid: 'Tabakprodukt-Produktnummer muss zwischen 1 und 30 liegen.',
  GTINInvalid: 'Eine GTIN ist eine 8, 12, 13 oder 14-stellige Ziffernfolge.',
  CnCodeInvalid: 'Der PCN muss mind. 8 und max. 10 Ziffern enthalten',
  BuendeOrderIdNotAnnounced: ' Die B\u00FCnde Bestell-ID ist unbekannt.',
  BuendeOrderPosDifferent: ' Die B\u00FCnder Bestell-Position past nicht.',
  OrderDeleteInvalidRecallReason1: 'Ung\u00FCltiger Widerrufsgrund.',
  AnnouncementOrderDoesntExists: 'Diese B\u00FCnde Bestell Nr. ist unbekannt.',
  CustomerOrderIdSize: 'Die ID der Kundenbestellung darf maximal 23 Zeichen lang sein',
  //+++++UpUi orders
  TobaccoProductIdNotAlignedWithCountry: 'Die Tabak Produkt ID passt nicht.',
  TobaccoProductNumberNotAlignedWithCountry: 'Die Tabak Produkt Nummer passt nicht.',
  ProductTypeOtherNotAlignedWithProductType: 'Tabak Produkttyp past nicht.',
  BuendeOrderPosNotAlignedWithDeliveryMethod: 'B\u00FCnde Bestellschein Position past nicht.',
  BuendeOrderIdNotAlignedWithDeliveryMethod: 'Die B\u00FCnde Bestell-ID past nicht.',
  AcrossBorderNotAlignedWithIsAcrossBorder: 'Transportweg past nicht.',
  MachineIdNotAlignedWithProcessType:
    'Es darf keine Maschinen-ID f\u00FCr manuell hergestellte Produkte angegeben werden.',
  EoidNotAlignedWithActingOnBehalf: 'Die EO-ID passt nicht zum verwalteten Einzelh\u00E4ndler.',
  UpuiOtherProductTypeSize: 'Andere Produkttyp darf nicht l\u00E4nger als 50 Zeichen sein.',

  //+++++ Errors (exception messages)
  errorWrongConfirmationCode: 'Falscher Bestätigungscode',
  errorInvalidData: 'Eingegebene Daten sind ungültig',

  //+++++API ERRORS
  EmptyMessageFromServer: 'Mit der Site ist etwas schiefgelaufen. Bitte wenden Sie sich an den Support.',

  //+++++ Server
  ServerError: 'Serverfehler 500.',
  DatabaseError: 'Datenbankfehler.',

  //+++++ OTHER ERRORS
  EoCodeIdentityNotConfirmed: 'Identit\u00E4t konnte nicht best\u00E4tigt werden.',
  EoCode3rdPartyIdentityNotConfirmed: 'Einrichtung EO-Id past nicht.',
  AllOtherErrors:
    'Bitte \u00FCberpr\u00FCfen Sie die Eingabewerte und versuchen Sie es erneut oder starten Sie diese Aktion erneut.',

  //+++++ TOKEN & PATH
  TokenEoidInvalid: 'Ung\u00FCltige EO-Id im token.',
  TokenEoidEmpty: 'Ung\u00FCltige EO-Id im token.',
  EoIdTokenMismatchReg3rd: 'EO-Id token past nicht.',
  EoIdTokenMismatch: 'EO-Id token past nicht.',
  EoIdPathTokenMismatch: 'EO-Id token past nicht.',

  //+++++ OPERATOR
  OperationWithDeactivatedOperator: 'Der EO ist dem System unbekannt.',
  OperatorDoesNotExistWithEoid: 'Der EO ist dem System unbekannt.',
  OperatorEoidTokenDoesNotMatchFacilityEoId: 'Ung\u00FCltige EO-Id im token.',
  OperatorUberDoesNotExistWithEoid: 'Der EO ist dem System unbekannt.',

  OperatorAddressInvalidInvoiceEmail: 'Ung\u00FCltige Rechnungs E-Mail.',
  OperatorAddressInvalidInvoiceAddress: 'Ung\u00FCltige Rechnungsadresse.',
  OperatorRetailerEoidTokenEoidMismatch: 'EO-Id token past nicht.',
  OperatorEoidTokenEoidMismatch: 'EO-Id token past nicht.',

  //+++++ Operator fields
  OnBehalfNull: 'Verwaltete Einzelh\u00E4ndler muss angegeben werden.',
  OnBehalfEoIdNull: 'Verwaltete Einzelh\u00E4ndler muss angegeben werden.',
  OperatorInvoiceAddressNull: 'Rechnungsaddresse muss angegeben werden.',
  OperatorNameNull: 'Betreiber Name muss angegeben werden.',
  OperatorNameSize: 'Betreiber Name muss zwischen 1 und 100 Zeichen lang sein.',
  OperatorNameAltSize: 'Alternativer Name muss zwischen 1 und 100 Zeichen lang sein.',

  TinTaxFormat: 'Falschen Steuer-ID Format.',
  InvalidVat: 'Ung\u00FCltig USt-ID. USt-ID muss zwischen 3 und 20 lang sein.',
  GermanVatNonGermanAdress: 'Eine deutsche USt-ID ist für nicht-deutsche Unternehmensadressen nicht erlaubt',
  VatNumberSize: 'USt-ID muss zwischen 8 und 14 lang sein.',
  TinNumberSize: 'Steuer-ID muss zwischen 8 und 16 Zeichen lang sein.',
  SeedExistsNull: 'VSt-Nr muss angegeben werden.',
  TinTaxFormatNull: 'Falschen Steuer-ID Format.',
  TinTaxFormatNotAligned: 'Falschen Steuer-ID Format.',
  EoTypeMax: 'Eo typ Maximalwert f\u00FCr ist 1.',
  EoTypeMin: 'Eo typ Mindestwert f\u00FCr ist 0.',
  EoIdGenerationProblem: 'Bei der Erzeugung der EO-ID ist ein Problem aufgetreten.',
  InvalidIssuerCountry: 'Ung\u00FCltiger Emittentenland.',
  ProblemWithEoCodeGeneration: 'Bei der Erzeugung des EO codes ist ein Problem aufgetreten.',
  GeneratedEoidInvalid: 'Bei der Erzeugung des EO codes ist ein Problem aufgetreten.',
  OperatorsDbRetailerEoidMissmatchToken: 'EO-Id token past nicht.',
  GeneratorIssuerIdEmpty: 'Die Issuer ID darf nicht leer sein.',
  GeneratorCodeVersionEmpty: 'L\u00E4nderkennzeichen darf nicht leer sein.',
  GeneratorCountryCodeEmpty: 'L\u00E4nderkennzeichen darf nicht leer sein.',
  //+++++Eoids fields
  OtherEoidsExistsNull: 'Andere EO-IDs muss angegeben werden.',
  OtherEoidsSize: 'Maximal 10 andere EO-IDs d\u00FCrfen angegeben werden.',
  OtherEoidsNotAligned: 'Wirtschaftsteilnehmer-Identifikationscode muss zwischen 1 und 50 Zeichen lang sein.',
  OtherEoidsAlreadyExists: 'Diese EO-IDs wurde bereits hinzugefügt.',

  //+++++ Onboarding
  InvoiceLanguageNotAlignedEoType: 'Ung\u00FCltiger Rechnungssprache.',
  InvoiceCompanyNameNotAlignedEoType: 'Ung\u00FCltiger Name der Rechnungsfirma.',
  InvoiceAddressNotAlignedEoType: 'Ung\u00FCltiger Rechnungsaddresse.',
  InvoicingCountryNotAlignedEoType: 'Ung\u00FCltiger Rechnungsland.',
  InvoicingEmailNotAlignedEoType: 'Ung\u00FCltiger Rechnungs E-Mail.',
  InvalidInvoicingLanguageKey: 'Ung\u00FCltiger Rechnungssprachcode.',
  AccreditedEoidNotAlignedNotNull: 'EO-Id token past nicht.',
  AccreditedEoidNotAlignedNull: 'EO-Id token past nicht.',
  AccreditedEoidRegistration: 'Die EO ID stammt vom deutschen ID Issuer. Mit dieser EO-ID können Sie sich nicht als "Zugelassener externer Betreiber" anmelden.',
  InvoiceEmailInvalidSize: 'Die Rechnungs-E-Mail Adresse muss mindestens 5 und maximal 80 Zeichen lang sein.', 
  mobilePhoneNull: 'Eine Mobilfunknummer muss angegeben werden.', 

  InvoiceStreetSize: 'Der Strassenname der Rechnungsanschrift darf nicht mehr länger als 40 Zeichen sein',
  InvoiceHouseNumberSize: 'Die Hausnummer der Rechnungsanschrift darf nicht länger als 20 Zeichen sein',
  InvoiceAddressZipCodeSize: 'Die Postleitzahl der Rechnungsanschrift muss mindestens 1 und maximal 10 Zeichen lang sein.',
  InvoiceCitySize: 'Der Ortsname der Rechnungsanschrift muss mindestens 1 und maximal 40 Zeichen lang sein.', 
  InvoiceCountryCodeInvalid: 'Ungültiger ISO Alpha-2 Länder Code', 
  
  //+++++Operations
  selectFilter: 'Bitte auswählen',
  idissuerOrderIdCaption: 'IDIssuer Bestell ID',
  customerOrderId: 'Kundenauftrags ID',
  eoRegistrationDataCaption: 'EO Registrierungsdaten',
  eoInvoiceDataCaption: 'EO Rechnungsdaten',

  //+++++ FACILITY
  FacilityDoesNotExistWithFid: 'Einrichtung ist dem System unbekannt.',
  FacilityDeactivatedOperation: 'Einrichtung ist dem System unbekannt.',
  FacilityEoidRequestEoid: 'EO-Id token past nicht.',
  //+++++Facilities fields
  FacilityTypeNull: 'Einrichtungstyp muss angegeben werden.',
  FacilityOrderStatusNull: 'Bestellstatus der Einrichtung muss angegeben werden.',
  FacilityExerciseNumberNull: '\u00DCbungsnummer muss angegeben werden.',
  //+++++Facilities token
  FacilityTokenMismatchReg3rd: 'Einrichtung EO-Id token past nicht.',
  //+++++eoid mismatch
  FacilityTokenMismatch: 'EO-Id token past nicht.',
  //+++++Fids fields
  OtherFidsExistsNull: 'Other Einrichtung muss angegeben werden.',
  OtherFidsSize: 'Sie k\u00F6nnen maximal 10 IDs hinzuf\u00FCgen.',
  OtherFidsElementSize: 'Einrichtung-Identifikationscode muss zwischen 1 und 50 Zeichen lang sein.',
  OtherFidsAlreadyExists: 'Diese Einrichtung wurde bereits hinzugefügt',

  //++++++++++MACHINES:
  MachineWithSerialNumberExists: 'Eine Maschine mit dieser Serien Nummer ist bereits gemeldet worden.',
  MachineDoesNotExistMid: 'Maschine ist dem System unbekannt.',
  MachinesDeactivatedOperation: 'Maschine ist dem System unbekannt.',
  //++++++++++Machines fields:
  MachineProducerNull: 'Maschinenhersteller muss angegeben werden.',
  MachinesProducerSizeInvalid: 'Maschinenhersteller muss zwischen 1 und 20 liegen.',
  MachinesModelNull: 'Maschinen-Modell muss angegeben werden.',
  MachinesModelSizeInvalid: 'Maschinen-Modell muss zwischen 1 und 20 liegen.',
  MachinesSerialNumberNull: 'Maschinenseriennummer muss angegeben werden.',
  MachinesSerialNumberSizeInvalid: 'Maschinenseriennummer muss zwischen 1 und 20 liegen.',
  MachinesCapacityNull: 'Maschinenkapazit\u00E4t muss angegeben werden.',
  MachinesCapacityMin: 'Mindestkapazit\u00E4t ist 1.',
  MachinesCapacityMax: 'Maximale Kapazit\u00E4t ist 999999.',
  MachinesCapacityMaximum: 'Maximale Kapazit\u00E4t ist 2147483647.',
  GiaiSize: 'GIAI muss 30 Zeichen lang sein.',

  //+++++ CONDITIONAL FIELDS
  NotAlignedEoidActingOnBehalf: 'Gemanagte Einzelh\u00E4ndler EoId past nicht.',
  NotAlignedSeedNumber: 'VSt-Nr past nicht.',
  NotAlignedOtherFids: 'Andere Fids past nicht.',
  NotAlignedOtherTypeDiscription: 'Einrichtungstyp past nicht.',

  //+++++ FIELDS
  EmailInvalid: 'Ung\u00FCltiger E-Mail.',
  EmailSize: 'Email muss zwischen 5 und 80 Zeichen lang sein.',
  EmailNull: 'Eine E-Mail Adresse muss angegeben werden', 
  EmailInvalidSize: 'Die Registrierungs-E-Mail Adresse muss mindestens 5 und maximal 80 Zeichen lang sein.', 

  //+++++ IDENTIFIER REGEX
  EoIdInvalid: 'EOID muss zwischen 1 und 50 Zeichen lang sein.',
  EoIdNull: 'Eoid muss angegeben werden.',
  FidInvalid: 'FID muss zwischen 1 und 50 Zeichen lang sein.',
  FidNull: 'Fid muss angegeben werden.',
  MidInvalid: 'MID muss zwischen 1 und 50 Zeichen lang sein.',
  MidNull: 'MID muss angegeben werden.',
  EoCodeIdInvalid: 'Eo code muss zwischen 1 und 50 Zeichen lang sein.',
  EoCodeNull: 'Eo code muss angegeben werden.',

  //++++++++++ ADDRESS
  AddressNull: 'Adresse muss angegeben werden.',
  ZipCodeInvalidCountry: 'Ung\u00FCltiger Postal code.',
  AddressZipCodeNull: 'Die Postleitzahl der Registrierungsanschrift muss angegeben werden',
  AddressHouseNumberNotAlignedWithFacilityType: 'Adresse Hausnummer past nicht.',
  AddressStreetNotAlignedWithFacilityType: 'Adresse Stra\u00DFe Nummer past nicht.',

  StreetNull: 'Stra\u00DFe muss angegeben werden.',
  StreetSize: 'Stra\u00DFe muss zwischen 1 und 40 Zeichen lang sein.',
  HouseNumberNull: 'Hausnummer muss angegeben werden.',
  HouseNumberSize: 'Hausnummer muss zwischen 1 und 20 Zeichen lang sein.',
  HouseNumberInvalid: 'Hausnummer darf nicht leer sein.',
  HouseNumberCharacters: 'Für Hausnummern sind nur die folgenden Zeichen erlaubt: Ziffern, Buchstaben, Leerzeichen, Sonderzeichen - /',
  ISO8859: 'Die Eingabe entspricht nicht dem ISO8859-15 Standard',
  CityNull: 'Stadt muss angegeben werden.',
  CitySize: 'Stadt muss zwischen 1 und 40 Zeichen lang sein.',
  ZipCodeSize: 'PLZ darf nicht l\u00E4nger als 10 Zeichen sein',
  AddInfoSize: 'Zusätzliche Adressinformationen dürfen maximal 50 Zeichen lang sein.',
  AddressZipCodeSize: 'PLZ darf nicht l\u00E4nger als 10 Zeichen sein.',
  wrongGermanZipCodeFormat: 'Falsches PLZ Format. Deutsche PLZ bestehen aus 5 Ziffern.',

  //+++++ COUNTRY
  CountryNull: 'Land muss angegeben werden.',
  CountryCodeInvalid: 'Ungültiger Länderkennzeichen.',
  CountryRegInvalid: 'Das Land der Registrierungsanschrift muss angegeben werden',


  //+++++ correction
  mobilePhoneSize: 'Mobile phone size darf nicht l\u00E4nger als 20 Zeichen sein.',
  mobilePhoneFormat: 'Falsches Format der Mobilfunknummer.',
  steuernummerFormat: 'Das Format der Steuernummer ist nicht korrekt',
  InvoicingEmailNotAligned3rd: 'Rechnungs E-Mail past nicht.',
  InvoicingAddressNotAligned3rd: 'Rechnungsaddresse past nicht.',
  InvoicingCountryNotAligned3rd: 'Rechnungsland past nicht.',
  invoicingCompanyNotAligned3rd: 'Rechnungsfirma past nicht.',
  invoicingLanguageNotAlignedEoType: 'Rechnungssprache past nicht.',

  //+++++ SORT
  SortBySortOrderNotAligned: 'Start und Gr\u00F6\u00DFe muss angegeben werden.',
  UnsupportedSortOrder: 'Nicht unterst\u00FCtzte Sortierung.',
  StartListSizeNotAligned:
    'Es m\u00FCssen beide Parameter (Start, Gr\u00F6\u00DFe) oder kein Parameter angegeben werden.',

  //+++++ KEYCLOAK
  UnableToDisableKeyCloakUser: 'Keycloack kann den Nutzer nicht deaktivieren.',

  //+++++ Unused
  EoTypeMustBeProvided: 'Eo typ muss angegeben werden.',
  InvoicingLanguageNull: 'Rechnungssprache ist leer.',

  //+++++ Secondary Repository
  SecRepoServiceCommunicationFail: 'Das Secondary Repository ist zur Zeit nicht erreichbar.',
  SecRepoFailedToSubmitData: 'Der Datensatz konnte nicht an das Secondary Repository \u00FCbertragen werden.',
  SecRepoDataValidationFail: 'Die ID ist dem Secondary Repository unbekannt.',
  SecondaryRepoExternalCommunicationError: 'Das Secondary Repository ist zur Zeit nicht erreichbar.',
  SecondaryRepoInvalidRequest: 'Das Secondary Repository ist zur Zeit nicht erreichbar.',
  SecondaryRepoUnknownError: 'Das Secondary Repository einen unbekannten Fehler Code zur\u00FCckgegeben.',
  RestClientExceptionSecondRepo: 'Das Secondary Repository ist zur Zeit nicht erreichbar.',
  SecondaryRepoServiceAccessDenied: 'Das Secondary Repository ist zur Zeit nicht erreichbar.',

  //++++++++++ 24.4
  AcrossBorderCountryMustBeProvided: 'Transportweg muss angegeben werden.',
  BuendeOrderMax: 'Positionsnummer darf nicht l\u00E4nger als 99 Zeichen sein.',
  BuendeOrderMin: 'Positionsnummer Mindestwert f\u00FCr ist 1.',
  BuendeOrderId: 'Die B\u00FCnde Bestell-ID darf nicht l\u00E4nger als 35 Zeichen sein.',
  BuendeOrderNotNull: 'Die B\u00FCnde Bestell-ID muss angegeben werden.',
  AnnouncementQuantityMin: 'Menge Mindestwert f\u00FCr ist 1.',
  AnnouncementQuantityMax: 'Menge darf nicht l\u00E4nger als 1000000 Zeichen sein.',
  AnnouncementSapOrderMax: 'Secondary Repository Bestell-ID darf nicht l\u00E4nger als 10 Zeichen sein.',
  AnnouncementSapOrderNotNull: 'Secondary Repository Bestell-ID muss angegeben werden.',
  OtherTypeDescriptionSize: 'Andere Einrichtungstyp description darf nicht l\u00E4nger als 50 Zeichen sein.',

  //+++++25.4
  EoidTaken: 'EO-Id ist bereits gemeldet worden.',
  //+++++30.4
  AccreditedOnlyDeIntendedMarket: 'Transportweg muss DE sein.',
  //+++++20.5
  InvalidIntendedMarket: 'Ung\u00FCltiger Absatzmarkt.',
  InvalidFacilityCountryGermanIdIssuer:
    ' Ung\u00FCltige Adresse. Die Adresse muss einen Ort im Hoheitsgebiet Deutschlands beschreiben.',
  //+++++21.5
  ProcessingError: 'Prozess fehlgeschlagen. Etwas ist schief gelaufen.',
  //+++++11.6
  InvalidAddressCountryGermanIdIssuer:
    'Ung\u00FCltige Adresse. Die Adresse muss einen Ort im Hoheitsgebiet Deutschlands beschreiben.',
  //+++++12.12
  OrderCancelPeriodElapsed: 'Bestellstornierungsfrist abgelaufen.',
  //+++++6.4.20
  InvalidISO885915Char: 'Zeichenfolge enthält ungültiges ISO885915-Zeichen',


  //+++++++SMSCode
  SMSOnly4Digits: 'Der SMS Code muss genau 4 Ziffern enthalten.',

  //+++++++Review Page
  ReviewData: 'Bitte prüfen Sie die Daten und fahren dann fort mit der Anmeldung',

  EconomicOperator: "Wirtschaftsteilnehmer",
  EconomicOperatorType: 'Wirtschaftsteilnehmertyp',

  //++++++++++Countries
  AUSTRIA: '\u00D6STERREICH',
  BELGIUM: 'BELGIEN',
  BULGARIA: 'BULGARIEN',
  CROATIA: 'KROATIEN',
  CYPRUS: 'ZYPERN',
  CZECH_REPUBLIC: 'TSCHECHISCHE REPUBLIK',
  DENMARK: 'D\u00C4NEMARK',
  ESTONIA: 'ESTLAND',
  FINLAND: 'FINNLAND',
  FRANCE: 'FRANKREICH',
  GERMANY: 'DEUTSCHLAND',
  GREECE: 'GRIECHENLAND',
  HUNGARY: 'UNGARN',
  IRELAND: 'IRLAND',
  ITALY: 'ITALIEN',
  LATVIA: 'LETTLAND',
  LITHUANIA: 'LITAUEN',
  LUXEMBOURG: 'LUXEMBURG',
  MALTA: 'MALTA',
  NETHERLANDS: 'NIEDERLANDE',
  POLAND: 'POLEN',
  PORTUGAL: 'PORTUGAL',
  ROMANIA: 'RUM\u00C4NIEN',
  SLOVAKIA: 'SLOWAKEI',
  SLOVENIA: 'SLOWENIEN',
  SPAIN: 'SPANIEN',
  SWEDEN: 'SCHWEDEN',
  UNITED_KINGDOM: 'VEREINIGTES K\u00D6NIGREICH',
  NONEU: 'NONEU',
  INTERNATIONAL_WATERS: 'INTERNATIONALE GEWÄSSER',
}

export default messages_de
